import gql from "graphql-tag";
import React, { useState, useEffect } from "react";
import { useQuery, useMutation } from "react-apollo-hooks";
import "../css/attachmentsStudent.css";
import { store } from "../store";
import UploadDocuments from "./UploadDocumentsStudents";
import axios from "axios";

const GET_TYPE_DOCUMENTS_STUDENTS_QUERY = gql`
  query GetTypeDocumentsStudents {
    type_documents_students {
      description
      id
    }
  }
`;

const GET_PERSON_NAME_QUERY = gql`
  query GetPersonName($email: String) {
    person(where: { email: { _eq: $email } }) {
      first_name
      last_name
    }
  }
`;

const GET_ENROLLMENTS_QUERY = gql`
  query GetEnrollments($turmaAtual: Int, $email: String) {
    enrollment(
      where: {
        registers: { turma: { _eq: $turmaAtual } }
        email: { _eq: $email }
      }
    ) {
      id
      email
      documents {
        id
        type_documents_student {
          id
          description
        }
        url
        status_documents_student {
          status_name
        }
      }
    }
  }
`;

const ADD_DOCUMENT_MUTATION = gql`
  mutation AddDocument(
    $enrollment_id: Int!
    $type_id: Int!
    $url: String!
    $status_id: Int!
    $comment: String!
  ) {
    insert_documents_students(
      objects: {
        enrollment_id: $enrollment_id
        type_id: $type_id
        url: $url
        status_id: $status_id
        comment: $comment
      }
    ) {
      returning {
        id
        enrollment_id
        type_id
        url
        status_id
        comment
        status_documents_student {
          status_name
        }
        type_documents_student {
          id
          description
        }
      }
    }
  }
`;
const UPDATE_DOCUMENT_MUTATION = gql`
  mutation UpdateDocument(
    $id: Int!
    $url: String!
    $status_id: Int!
    $comment: String!
  ) {
    update_documents_students(
      where: { id: { _eq: $id } }
      _set: { url: $url, status_id: $status_id, comment: $comment }
    ) {
      returning {
        id
        enrollment_id
        type_id
        url
        status_id
        comment
        status_documents_student {
          status_name
        }
        type_documents_student {
          id
          description
        }
      }
    }
  }
`;

const getStatusClass = (status) => {
  switch (status) {
    case "Aprovado":
      return "status-aprovado";
    case "Pendente":
      return "status-pendente";
    case "Reprovado":
      return "status-reprovado";
    case "Faltando":
      return "status-reprovado";
    default:
      return "";
  }
};

const mapStatusToText = (status) => {
  switch (status) {
    case "Pendente":
      return "Em análise";
    case "Aprovado":
      return "Validado";
    case "Reprovado":
      return "Rejeitado";
    default:
      return status;
  }
};

export function AttachmentsPage() {
  const turmaAtual = store.ui.turma.id;
  const { data: typesData } = useQuery(GET_TYPE_DOCUMENTS_STUDENTS_QUERY);
  const { data: enrollmentsData, loading, error, refetch } = useQuery(
    GET_ENROLLMENTS_QUERY,
    {
      variables: { turmaAtual, email: store.email },
    }
  );
  const { data: personData } = useQuery(GET_PERSON_NAME_QUERY, {
    variables: { email: store.email },
  });
  const [addDocument] = useMutation(ADD_DOCUMENT_MUTATION);
  const [updateDocument] = useMutation(UPDATE_DOCUMENT_MUTATION);
  const [documents, setDocuments] = useState([]);

  useEffect(() => {
    if (enrollmentsData && typesData) {
      const enrollment = enrollmentsData.enrollment[0];
      if (enrollment) {
        const allDocuments = typesData.type_documents_students.map((type) => {
          const existingDoc = enrollment.documents.find(
            (doc) => doc.type_documents_student.id === type.id
          );
          return (
            existingDoc || {
              id: null,
              type_documents_student: type,
              url: "",
              status_documents_student: { status_name: "Faltando" },
              comment: "",
            }
          );
        });
        setDocuments(allDocuments);
      }
    }
  }, [enrollmentsData, typesData]);

  const handleFileUpload = async (fileId, typeId, docId = null) => {
    try {
      const enrollmentId = enrollmentsData.enrollment[0].id;
      let response;
      if (docId) {
        response = await updateDocument({
          variables: {
            id: docId,
            url: fileId,
            status_id: 3, // Pendente
            comment: "",
          },
        });
      } else {
        response = await addDocument({
          variables: {
            enrollment_id: enrollmentId,
            type_id: typeId,
            url: fileId,
            status_id: 3, // Pendente
            comment: "",
          },
        });
      }

      const updatedDocument = response.data.insert_documents_students
        ? response.data.insert_documents_students.returning[0]
        : response.data.update_documents_students.returning[0];

      setDocuments((prevDocuments) =>
        prevDocuments.map((doc) =>
          doc.type_documents_student.id === typeId
            ? {
                ...doc,
                ...updatedDocument,
                status_documents_student: { status_name: "Pendente" },
              }
            : doc
        )
      );

      // Enviar email de notificação
      const documentoNome =
        documents.find((doc) => doc.type_documents_student.id === typeId)
          ?.type_documents_student.description || "Documento";

      const turma = store.ui.turma.name || store.ui.turma.id;
      const email = store.email;

      let nomeAluno = email;
      if (personData && personData.person && personData.person.length > 0) {
        const person = personData.person[0];
        nomeAluno = `${person.first_name} ${person.last_name}`;
      }

      try {
        await axios.post(
          "/.netlify/functions/mailnotification",
          {
            to: "secretaria@ceconte.com.br",
            text: `Novo documento anexado no Painel do Aluno

Um aluno acabou de anexar um novo documento no sistema:

Informações do documento:
- Tipo de documento: ${documentoNome}
- Status: Pendente (aguardando revisão)
- Link do documento: https://drive.google.com/file/d/${fileId}/view

Informações do aluno:
- Nome: ${nomeAluno}
- Email: ${email}
- Turma: ${turma}

Esta é uma notificação automática. Por favor, acesse o painel administrativo para aprovar ou rejeitar este documento.`,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("auth0:id_token")}`,
            },
          }
        );
      } catch (error) {
        console.error("Erro ao enviar notificação por email:", error);
      }

      await refetch();
    } catch (error) {
      console.error("Erro ao adicionar/atualizar o documento:", error);
    }
  };

  if (loading) return <div>Carregando...</div>;
  if (error) return <div>Ocorreu um erro ao buscar os dados.</div>;

  return (
    <div className="container">
      <h1 className="titleAttachments">Documentos - Anexos</h1>
      <table className="table">
        <thead>
          <tr>
            <th className="th">Status</th>
            <th className="th">Documento</th>
            <th className="th">Comentário</th>
            <th className="th">Ações</th>
          </tr>
        </thead>
        <tbody>
          {documents.map((doc) => (
            <tr key={doc.type_documents_student.id}>
              <td
                className={`td ${getStatusClass(
                  doc.status_documents_student.status_name
                )}`}
              >
                <span />
                {mapStatusToText(doc.status_documents_student.status_name)}
              </td>
              <td className="td">
                {doc.status_documents_student.status_name === "Faltando" ? (
                  doc.type_documents_student.description
                ) : (
                  <a
                    href={`https://drive.google.com/file/d/${doc.url}/view`}
                    target="_blank"
                    rel="noreferrer"
                    className="link"
                  >
                    Abrir {doc.type_documents_student.description}
                  </a>
                )}
              </td>
              <td className="td">{doc.comment || ""}</td>
              <td className="td">
                {(doc.status_documents_student.status_name === "Reprovado" ||
                  doc.status_documents_student.status_name === "Faltando") && (
                  <UploadDocuments
                    field={doc.url}
                    oldFile={doc.url}
                    onFileUpload={(fileId) =>
                      handleFileUpload(
                        fileId,
                        doc.type_documents_student.id,
                        doc.id
                      )
                    }
                  />
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
